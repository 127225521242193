import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { withTheme } from "@material-ui/core/styles";
import {
  AppHeader,
  SmartumLogo,
  LanguageSelect,
  LanguageOption,
} from "../StyledComponents";
import smartumCompanyLogo from "../../assets/smartum-logo.svg";

class AppHeaderComponent extends PureComponent<{
  onChangeLanguage: (data: string) => any;
  value: string;
  hasGenericError: boolean;
  isVenueSearch?: boolean;
  theme: any;
}> {
  public static defaultProps = {
    hasGenericError: false,
    logoColor: false,
  };

  render() {
    const { isVenueSearch, theme } = this.props;

    return (
      <AppHeader
        style={{
          borderBottom: isVenueSearch
            ? `1px solid ${theme.palette.divider}`
            : "none",
        }}
      >
        <a href="/">
          <SmartumLogo
            src={smartumCompanyLogo}
            style={{
              filter: isVenueSearch ? "invert(1)" : "invert(0)",
            }}
          />
        </a>

        <LanguageSelect
          hasGenericError={this.props.hasGenericError}
          isVenueSearch={this.props.isVenueSearch}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            this.props.onChangeLanguage(e.target.value)
          }
          value={this.props.value}
        >
          <FormattedMessage
            id="AppHeader.language.finnish"
            defaultMessage="Suomi"
          >
            {(msg) => <LanguageOption value="fi">{msg}</LanguageOption>}
          </FormattedMessage>
          <FormattedMessage
            id="AppHeader.language.english"
            defaultMessage="Englanti"
          >
            {(msg) => <LanguageOption value="en">{msg}</LanguageOption>}
          </FormattedMessage>
        </LanguageSelect>
      </AppHeader>
    );
  }
}

export default withTheme(AppHeaderComponent);
