import "react-app-polyfill/ie11";
import "core-js";
import "./polyfills/intl.js";
import React from "react";
// eslint-disable-next-line react/no-deprecated
import { render } from "react-dom";
import "./index.css";
import "./styles/fonts.css";
import App from "./App";

const domNode = document.getElementById("root");
if (domNode) {
  render(<App />, domNode);
}
