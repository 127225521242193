import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import AppHeader from "../../components/Header";
import VenueSearchFooter from "../../components/Footer/VenueSearchFooter";
import { AppContext } from "../../components/AppProvider";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    flexGrow: 1,
  },
  routesGrid: {
    maxWidth: theme.breakpoints.values.lg,
    flexGrow: 1,
    margin: "auto",
  },
}));

interface AppLayoutProps {
  children: React.ReactNode;
  useGrayBackground?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  useGrayBackground = false,
}) => {
  const { onChangeLanguage, locale } = React.useContext(AppContext);
  const classes = useStyles();

  return (
    <>
      <AppHeader
        onChangeLanguage={onChangeLanguage}
        value={locale}
        hasGenericError={false}
        isVenueSearch={true}
      />
      <Grid
        container
        className={classes.rootGrid}
        direction="column"
        style={{
          backgroundColor: useGrayBackground ? "#F5F5F5" : undefined,
        }}
      >
        <Box mt={6} />
        <Grid item className={classes.routesGrid}>
          {children}
        </Grid>
        <Box mt={10} />
        <VenueSearchFooter />
      </Grid>
    </>
  );
};

export default AppLayout;
