import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "./config";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RootContainer from "./routes/root/RootContainer";
import AppProvider from "./components/AppProvider";
import Receipt from "./routes/receipt";
import ErrorPage from "./components/ErrorView/ErrorPage";

export const stripePromise = loadStripe(
  config.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Elements stripe={stripePromise}>
                <RootContainer />
              </Elements>
            }
          />
          <Route path="receipt" element={<Receipt />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
};

// export default withProviders(App);
export default App;
