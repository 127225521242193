import createTheme from "@material-ui/core/styles/createTheme";
import "../styles/fonts.css";

const breakpoints = {
  values: {
    xs: 100,
    sm: 200,
    md: 500,
    lg: 1000,
    xl: 1200,
  },
};

const baseTheme = createTheme({
  breakpoints,
  typography: {
    fontFamily: ["Work Sans", "Ivar Display"].join(","),
  },
  palette: {
    primary: {
      main: "#000000",
      light: "#CCCCCC",
      dark: "#999999",
      contrastText: "#1E1E1E",
    },
    error: {
      main: "#B3261E",
    },
    secondary: {
      main: "#999999",
    },
    text: {
      primary: "#1E1E1E",
      secondary: "#4D4D4D",
    },
    background: {
      default: "#FFFFFF",
      paper: "#F5F5F5",
    },
    action: {
      hover: "#CCCCCC",
      selected: "#F5F5F5",
    },
    divider: "#E6E6E6",
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  shape: {
    borderRadius: 16,
  },
});

const MuiTheme = createTheme(baseTheme, {
  typography: {
    h1: {
      fontSize: "2rem",
      fontFamily: "Ivar Display",
      textAlign: "center",
      [baseTheme.breakpoints.up("lg")]: {
        fontSize: "3rem",
      },
    },
    h3: {
      fontSize: "1.5rem",
      fontFamily: "Ivar Display",
      textAlign: "center",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.5rem",
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontSize: ".875rem",
      fontWeight: 100,
    },
    subtitle1: {
      fontSize: "1rem",
      lineHeight: "125%",
    },
    subtitle2: {
      fontSize: "1rem",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: 24,
        color: "#F8F8FF",
        backgroundColor: "#1E1E1E",
        "&:hover": {
          backgroundColor: "#4D4D4D",
        },
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
      },
      contained: {
        marginRight: "8px",
        height: "56px",
        color: "#F8F8FF",
        backgroundColor: "#1E1E1E",
        borderRadius: 8,
        "&:hover": {
          backgroundColor: "#4D4D4D",
        },
        "&.Mui-disabled": {
          backgroundColor: "#CCCCCC",
          color: "#999999",
        },
      },
      outlined: {
        paddingTop: "6px !important",
        paddingBottom: "6px !important",
        borderRadius: 16,
        borderColor: "#CCCCCC",
        backgroundColor: "#FFF",
        color: "#1E1E1E",
        "&:hover": {
          backgroundColor: "#CCCCCC",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        marginRight: "8px",

        "&.searchOutlinedInput": {
          borderRadius: "24px",
          backgroundColor: "#F5F5F5",
          "&:hover": {
            backgroundColor: "#CCCCCC",
          },
          "&.Mui-focused": {
            backgroundColor: "#F5F5F5",
          },
          "& input": {
            color: "#1E1E1E",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
        "&.formOutlinedInput": {
          borderRadius: "16px",
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: "#FFFFFF",
        padding: baseTheme.spacing(4),
        boxShadow: "unset",
        marginBottom: 0,
        borderBottom: `1px solid #E6E6E6`,

        "&.Mui-expanded": {
          margin: 0,
          "&:first-child": {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          },
          "&:last-child": {
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
          },
        },
        "&.MuiAccordion-rounded": {
          "&:first-child": {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          },
          "&:last-child": {
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        transition: "none",
        margin: 0,
        "&.Mui-expanded": {
          margin: 0,
        },
      },
      root: {
        transition: "none",
        "&.Mui-expanded": {
          margin: 0,
          minHeight: "20px",
        },
      },
      expandIcon: {
        padding: 0,
      },
    },
    MuiDialog: {
      paper: {
        padding: baseTheme.spacing(6),
        backgroundColor: baseTheme.palette.background.default,
      },
      paperFullScreen: {
        borderRadius: "24px 24px 0 0",
      },
    },
    MuiDialogContent: {
      root: {
        padding: baseTheme.spacing(4),
        flexGrow: 0,
      },
    },
  },
});

export default MuiTheme;
