import React from "react";
import { Map as LeafletMap, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import marker from "../../assets/marker.svg";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";

const pointerIcon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  iconAnchor: [27, 46],
});

type MapProps = {
  latitude: number;
  longitude: number;
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      height: "240px",
    },
    height: "144px",
    width: "100%",
  },
}));

export default function VenueMap(props: Readonly<MapProps>) {
  const classes = useStyles();

  const { latitude, longitude } = props;

  return (
    <Box className={classes.container}>
      <LeafletMap
        center={[latitude, longitude]}
        zoom={15}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2hyaXN0aWFuc29obGJlcmciLCJhIjoiY2prdXRkYXFwMGpqczNwcXFsc2YzMHQyYyJ9.FtojERB1GdUeql5ed69uuw" />
        <Marker position={[latitude, longitude]} icon={pointerIcon}></Marker>
      </LeafletMap>
    </Box>
  );
}
