import React, { useState } from "react";
import VenueSearch from "../../components/VenueSearch";
import PaymentSelect from "../../containers/PaymentSelect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { disableSelectKey, venueIdKey, venuePrefixKey } from "../../constants";
import Pay from "../../components/Pay";
import { Venue } from "../../types/venue";
import AppLayout from "../../components/Layout/AppLayout";

const RootContainer = () => {
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const urlParams = () => {
    const venueId = searchParams.get(venueIdKey);
    const disablePaymentSelect = searchParams.get(disableSelectKey);
    const code = searchParams.get("code");
    return { venueId, disablePaymentSelect, code };
  };

  const { venueId, disablePaymentSelect, code } = urlParams();

  const urlHasVenueIdKey = venueId?.includes(venuePrefixKey);

  if (disablePaymentSelect === "1" || code) {
    try {
      return <Pay />;
    } catch (error) {
      navigate("/error", { state: { message: "Payment processing failed" } });
    }
  }

  const navigateToPaymentSelect = (venue: Venue) => {
    setSelectedVenue(venue);
    navigate(`/?${venueIdKey}=${venue.id}`);
  };

  return (
    <AppLayout>
      {urlHasVenueIdKey ? (
        <PaymentSelect venue={selectedVenue} />
      ) : (
        <VenueSearch navigateToPaymentSelect={navigateToPaymentSelect} />
      )}
    </AppLayout>
  );
};

export default RootContainer;
