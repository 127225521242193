import React, { useState } from "react";
import {
  MenuItem,
  Checkbox,
  Button,
  Menu,
  Box,
  Typography,
  makeStyles,
  IconButton,
  useMediaQuery,
  useTheme,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BENEFIT_OPTIONS } from "../../../constants";
import { FormattedMessage, useIntl } from "react-intl";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dropDownMenu: {
    marginTop: "10px",
  },
  dropDownContainer: {
    padding: theme.spacing(10),
    backgroundColor: theme.palette.background.default,
    width: "375px",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      padding: theme.spacing(5),
    },
  },
  showResultsButton: {
    width: "375px",
    [theme.breakpoints.down("sm")]: { width: "80%" },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  headerTitle: {
    flexGrow: 1,
  },
  menuList: {
    paddingTop: "0px",
    paddingBottom: "0px",
    width: "100%",
  },
}));

type BenefitsDropdownProps = {
  handleShowResults: (benefits: string[]) => void;
};

export default function BenefitsDropdown(
  props: Readonly<BenefitsDropdownProps>
) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();

  const [benefits, setBenefits] = useState<string[]>([]);
  const { handleShowResults } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleShowResults(benefits);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === "all") {
      const isAllSelected = benefits.includes("all");
      setBenefits(
        isAllSelected ? [] : BENEFIT_OPTIONS.map((option) => option.value)
      );
    } else {
      toggleBenefit(value);
    }
  };

  const toggleBenefit = (value: string) => {
    const newBenefits = benefits.includes(value)
      ? benefits.filter((b) => b !== value)
      : [...benefits, value];

    setBenefits(newBenefits);
  };

  const displayText =
    benefits.length > 0
      ? benefits.includes("all")
        ? intl.formatMessage({
            id: "VenueSearch.allResults",
          })
        : benefits
            .map((b) => intl.formatMessage({ id: `benefits.${b}` }))
            .join(", ")
      : intl.formatMessage({
          id: "VenueSearch.filterResults",
        });

  return (
    <Box>
      <Button
        variant="outlined"
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
      >
        {displayText}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.dropDownMenu}
        classes={{ list: classes.menuList }}
      >
        <Box className={classes.dropDownContainer}>
          {isMobile && (
            <Box className={classes.headerContainer}>
              <IconButton onClick={handleClose} color="primary">
                <Close />
              </IconButton>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.headerTitle}
              >
                <FormattedMessage id="VenueSearch.benefitTypes" />
              </Typography>
            </Box>
          )}
          {BENEFIT_OPTIONS.map((option) => (
            <MenuItem key={option.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      benefits.includes("all") ||
                      benefits.includes(option.value)
                    }
                    onChange={handleChange}
                    value={option.value}
                  />
                }
                label={
                  option.value === "all"
                    ? intl.formatMessage({ id: "VenueSearch.allResults" })
                    : intl.formatMessage({ id: `benefits.${option.value}` })
                }
              />
            </MenuItem>
          ))}
          <Box mt={5} />
          <Button
            className={classes.showResultsButton}
            onClick={handleClose}
            fullWidth
          >
            <FormattedMessage id="VenueSearch.showResults" />
          </Button>
        </Box>
      </Menu>
    </Box>
  );
}
