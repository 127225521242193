import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  disableSelectKey,
  RECEIPT_SUCCESS_URL,
  successUrlKey,
  venueIdKey,
  cancelUrlKey,
  PAYMENT_CANCEL_URL,
} from "../../constants";
import { Benefit, Venue } from "../../types/venue";
import VenueItem from "../../components/VenueSearch/VenueItem";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import AmountInput from "../../components/PaymentSelection/AmountInput";
import PaymentMethodContainer from "../../components/PaymentSelection/PaymentMethodContainer";
import { hasCheckoutURLParams } from "../../utils/url";
import { getVenue } from "../../utils/api";
import {
  getPriorityBenefit,
  isAcceptingOnlyQrPayments,
  isGiftcardBenefitType,
  venueHasBenefit,
} from "../../utils/benefit";
import {
  venueAcceptsGiftcardPayment,
  venueAcceptsMobilePayment,
} from "../../utils/venue";
import {
  getCheckoutRedirectUrl,
  getLunchLimits,
  makeGiftcardPayment,
} from "../../utils/api/api";
import { toFloat } from "../../utils/amount/toFloat";
import { toCents } from "../../utils/amount/toCents";
import { GiftcardAccount } from "../../types/account";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage, useIntl } from "react-intl";
import GiftCardInputContainer from "../../components/PaymentSelection/GiftCardInputContainer";
import { GiftcardPayment } from "../../types/payment";
import PaymentConfirmDialog from "../../components/PaymentConfirmDialog";
import { centsToEuros } from "../../utils/amount/toEuros";
import BenefitSelection from "../../components/PaymentSelection/BenefitSelection";
import useStyles from "./styles";
import { validateValue } from "../../utils/validation/validateValue";
import {
  getGiftcardPaymentValidationError,
  getGiftcardValidationError,
} from "../../utils/validation/giftcardValidationErrors";

type PaymentSelectProps = {
  venue: Venue | null;
};

export type PaymentMethod = "giftcard" | "benefit";
export type AccordionName = "amount" | "paymentMethod";
export type AmountError = {
  key: string;
  isLunchAmountErr?: boolean;
  values?: { [key: string]: string };
};

const PaymentSelect = ({ venue: propVenue }: PaymentSelectProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [venue, setVenue] = useState<Venue | null>(propVenue);
  const [amount, setAmount] = useState<string>("0");
  const [claimCode, setClaimCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isPaymentConfirmVisible, setPaymentConfirmVisible] =
    useState<boolean>(false);
  const [amountError, setAmountError] = useState<AmountError | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );
  const [lunchLimits, setLunchLimits] = useState<{
    max: number;
    min: number;
  } | null>(null);
  const [giftcardAccount, setGiftcardAccount] =
    useState<GiftcardAccount | null>(null);
  const [amountExpanded, setAmountExpanded] = useState<boolean>(true);
  const [paymentMethodExpanded, setPaymentMethodExpanded] =
    useState<boolean>(true);
  const [isPaymentVisible, setPaymentVisible] = useState<boolean>(false);
  const [amountDisabled, setAmountDisabled] = useState<boolean>(false);
  const [isGiftcardPaymentDisabled, setGiftcardPaymentDisabled] =
    useState<boolean>(false);
  const [selectedBenefit, setSelectedBenefit] = useState<{
    value: Benefit;
    urlParam: boolean;
  } | null>(null);

  const [paymentMethodValidationError, setPaymentMethodValidationError] =
    useState<string | null>(null);
  const [emailValidationError, setEmailValidationError] = useState<
    string | null
  >(null);
  const [validationErrorValues, setValidationErrorValues] = useState<
    object | null
  >(null);
  const [claimCodeError, setClaimCodeError] = useState<string | null>(null);
  const [missingClaimCode, setMissingClaimCode] = useState<string | null>(null);
  const [paymentStarted, setPaymentStarted] = useState<boolean>(false);
  const [giftcardLoading, setGiftcardLoading] = useState<boolean>(false);

  const urlHasCheckoutParams = hasCheckoutURLParams();
  const venueId = searchParams.get(venueIdKey);

  useEffect(() => {
    if (!venue && venueId) {
      fetchVenue(venueId);
    }
  }, [venueId, venue]);

  // Show payment view to venue without 'mobile' payment_method if url contains checkout params
  useEffect(() => {
    if (venue) {
      const paymentIsVisible =
        venueAcceptsMobilePayment(venue) || urlHasCheckoutParams;
      setPaymentVisible(paymentIsVisible);
    }
  }, [venue, urlHasCheckoutParams]);

  useEffect(() => {
    if (venue && venue.accepted_benefits.includes("lunch")) {
      fetchLunchLimits();
    }
  }, [venue]);

  useEffect(() => {
    // redirect to checkout payment if benefit type in url does not accept giftcard payments
    const benefit = searchParams.get("benefit");
    if (benefit && !isGiftcardBenefitType(benefit)) {
      const params = new URLSearchParams(window.location.search);
      params.set(disableSelectKey, "1");

      navigate(`/?${params.toString()}`);
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    if (venue && !venueAcceptsGiftcardPayment(venue)) {
      setPaymentMethod("benefit");
      setGiftcardPaymentDisabled(true);
    }
  }, [venue]);

  useEffect(() => {
    const amountParam = searchParams.get("amount");
    if (amountParam) {
      const amountInEuros = (parseInt(amountParam) / 100).toFixed(2);
      setAmount(amountInEuros);
      setAmountDisabled(true);
      setAmountExpanded(false);
      setPaymentMethodExpanded(true);
    }
    const benefitParam = searchParams.get("benefit");
    if (benefitParam) {
      setSelectedBenefit({ value: benefitParam as Benefit, urlParam: true });
    }
  }, [searchParams]);

  useEffect(() => {
    if (paymentMethod === "benefit") {
      setGiftcardAccount(null);
      setClaimCode("");
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (paymentMethod === "benefit" && venue) {
      const acceptedBenefits = venue.accepted_benefits;
      // If benefit has been defined in url, do not override value when selecting benefit payment method
      if (acceptedBenefits.length === 1 && !selectedBenefit?.urlParam) {
        setSelectedBenefit({ value: acceptedBenefits[0], urlParam: false });
        setPaymentMethodExpanded(false);
      }
    }
  }, [paymentMethod, venue, selectedBenefit?.urlParam]);

  const fetchVenue = async (id: string) => {
    try {
      const response = await getVenue(id);
      setVenue({ ...response.data });
    } catch {
      navigate("/error");
    }
  };

  const fetchLunchLimits = async () => {
    const { constants } = await getLunchLimits();
    if (constants) {
      setLunchLimits({
        max: constants.PAYMENT_LIMIT_LUNCH_MAX_CENTS,
        min: constants.PAYMENT_LIMIT_LUNCH_MIN_CENTS,
      });
    }
  };

  const handleBenefitSelection = (benefit: Benefit) => {
    setSelectedBenefit({ value: benefit, urlParam: false });
    setPaymentMethodExpanded(false);
  };

  const handleAccordionChange =
    (panel: AccordionName) =>
    (event: React.ChangeEvent<object>, newExpanded: boolean) => {
      if (panel === "paymentMethod") {
        if (paymentMethod === "giftcard" && isValidGiftcard) {
          setPaymentMethodExpanded(false);
        }
        if (paymentMethod === "benefit" && selectedBenefit?.value) {
          setPaymentMethodExpanded(false);
        }
      }
      panel === "amount"
        ? setAmountExpanded(newExpanded)
        : setPaymentMethodExpanded(newExpanded);
    };

  const validateAmount = (value: string, setErrorKey?: boolean): boolean => {
    const parsedAmount = toFloat(value);
    const isValidAmount = parsedAmount > 0;
    setAmountExpanded(!isValidAmount);
    let amountErr = null;
    if (setErrorKey) {
      amountErr = isValidAmount
        ? null
        : { key: "AmountInput.error.missingAmount" };
    }
    setAmountError(amountErr);
    return isValidAmount;
  };

  const validateInputs = () => {
    validateAmount(amount, true);

    validateValue(
      !!paymentMethod,
      setPaymentMethodValidationError,
      "PaymentSelect.error.paymentMethodNotSelected"
    );

    if (paymentMethod === "giftcard") {
      validateValue(
        !!(claimCode && !claimCodeError),
        setMissingClaimCode,
        `GiftcardInput.error.missing_claimcode`
      );

      validateValue(
        !!email,
        setEmailValidationError,
        `GiftcardInput.error.missing_email`
      );

      if (email) {
        const isValidEmail = (email: string) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
        };

        validateValue(
          isValidEmail(email),
          setEmailValidationError,
          `GiftcardInput.error.invalid_email`
        );
      }
    }
  };

  const handleBeneficiaryPayment = useCallback(async () => {
    if (!venue || !selectedBenefit) {
      return;
    }
    const amountInCents = toCents(amount);
    const { url, error } = await getCheckoutRedirectUrl(
      venue,
      amountInCents,
      selectedBenefit?.value
    );

    if (error) {
      if (error === "parameter_invalid" && lunchLimits) {
        setAmountError({
          key: "PaymentSelect.lunchPaymentInfo",
          isLunchAmountErr: true,
          values: {
            min: centsToEuros(lunchLimits?.min) || "0.00",
            max: centsToEuros(lunchLimits?.max) || "0.00",
          },
        });
        setAmountExpanded(true);
      } else {
        navigate("/error");
      }
      return;
    }

    if (url) {
      window.location.href = url;
    }
  }, [
    venue,
    selectedBenefit,
    amount,
    lunchLimits,
    setAmountError,
    setAmountExpanded,
    navigate,
  ]);

  const handleGiftcardPayment = async () => {
    if (!venue || !giftcardAccount) {
      return;
    }
    // If benefit is defined in url, value is never changed by user
    const preSelectedBenefit = selectedBenefit?.urlParam
      ? selectedBenefit.value
      : null;
    const paymentBenefit =
      preSelectedBenefit || getPriorityBenefit(venue, giftcardAccount);

    const giftcardPayment: GiftcardPayment = {
      from: giftcardAccount.token,
      to: venue.id,
      amount: toCents(amount),
      benefit: paymentBenefit,
      email,
    };

    const { jwtToken, receiptToken, error } =
      await makeGiftcardPayment(giftcardPayment);
    handlePostPaymentRedirect(jwtToken, receiptToken);
    if (error) {
      const cancelUrl = searchParams.get(cancelUrlKey);
      if (cancelUrl) {
        window.location.replace(`${cancelUrl}`);
      } else {
        navigate(`${PAYMENT_CANCEL_URL}`);
      }
    }
  };

  const handlePostPaymentRedirect = (
    jwtToken: string,
    receiptToken: string
  ) => {
    if (!jwtToken && !receiptToken) {
      return;
    }
    const successUrl = searchParams.get(successUrlKey);

    if (successUrl && successUrl !== RECEIPT_SUCCESS_URL) {
      window.location.replace(`${successUrl}?jwt=${jwtToken}`);
    } else {
      navigate(`/receipt?jwt=${receiptToken}`);
    }
  };

  const giftcardPaymentValidationError = useMemo((): null | string => {
    return getGiftcardPaymentValidationError(giftcardAccount, amount);
  }, [giftcardAccount, amount]);

  const giftcardValidationError = useMemo((): null | string => {
    return getGiftcardValidationError(
      giftcardAccount,
      selectedBenefit?.urlParam ? selectedBenefit.value : null,
      venue,
      intl,
      setValidationErrorValues
    );
  }, [giftcardAccount, selectedBenefit, intl, venue]);

  // giftcard account errors are shown as priority, amount error secondary
  const displayedValidationError =
    giftcardValidationError ||
    amountError?.key ||
    giftcardPaymentValidationError ||
    paymentMethodValidationError;

  const venueHasLunchBenefit = venueHasBenefit(venue, "lunch");
  const venueHasCommuterBenefit = venueHasBenefit(venue, "commuter");

  const isValidGiftcard =
    !giftcardValidationError && !!giftcardAccount && !claimCodeError;

  useEffect(() => {
    if (isValidGiftcard) {
      setPaymentMethodExpanded(false);
    }
  }, [isValidGiftcard]);

  useEffect(() => {
    // empty errors when payment method is changed
    setPaymentMethodValidationError(null);
    setEmailValidationError(null);
    setClaimCodeError(null);
    setMissingClaimCode(null);
  }, [paymentMethod]);

  const onPaymentClick = () => {
    validateInputs();
    setPaymentStarted(true);
  };

  const handlePayment = useCallback(async () => {
    if (paymentMethod === "benefit") {
      // if url already contains checkoutparams -> append disableSelectKey to url parameters
      if (urlHasCheckoutParams) {
        const params = new URLSearchParams(window.location.search);
        params.set(disableSelectKey, "1");
        navigate(`/?${params.toString()}`);
      } else {
        await handleBeneficiaryPayment();
      }
    } else {
      const isValidGiftcardFields =
        isValidGiftcard &&
        !emailValidationError &&
        !giftcardPaymentValidationError &&
        !missingClaimCode;
      if (isValidGiftcardFields) {
        setPaymentConfirmVisible(true);
      }
    }
  }, [
    paymentMethod,
    urlHasCheckoutParams,
    navigate,
    handleBeneficiaryPayment,
    isValidGiftcard,
    emailValidationError,
    giftcardPaymentValidationError,
    missingClaimCode,
  ]);

  useEffect(() => {
    if (!paymentStarted || giftcardLoading) {
      return;
    }
    setPaymentStarted(false);

    if (amountError || paymentMethodValidationError) {
      return;
    }
    handlePayment();
  }, [
    paymentStarted,
    amountError,
    paymentMethodValidationError,
    handlePayment,
    giftcardLoading,
  ]);

  const handleBackToVenueSearch = () => {
    navigate("/");
  };

  const showEmailError = emailValidationError && isValidGiftcard;

  return (
    <Box>
      <>
        {venue ? (
          <>
            <Box mx={{ sm: 6, lg: 10 }}>
              <IconButton onClick={handleBackToVenueSearch} color="primary">
                <ArrowBackIcon />
              </IconButton>
              <Box mt={2} />
              <VenueItem
                venue={venue}
                isQrOnly={isAcceptingOnlyQrPayments(venue)}
                showPaymentButton={false}
              />
            </Box>
            {isPaymentVisible && (
              <Box
                bgcolor="background.paper"
                p={{ sm: 6 }}
                mx={{ sm: 0, lg: 10 }}
              >
                <Typography variant="h4">
                  <FormattedMessage id="PaymentSelect.makePaymentToVenue" />
                </Typography>
                <Box mt={8} />
                {!urlHasCheckoutParams && (
                  <Typography variant="body1">
                    <FormattedMessage id="PaymentSelect.paymentDisclaimer" />
                  </Typography>
                )}
                <Box mt={6} />
                {(venueHasLunchBenefit || venueHasCommuterBenefit) &&
                  venueAcceptsGiftcardPayment(venue) && (
                    <Alert severity="info">
                      <FormattedMessage id="PaymentSelect.error.giftCardUsageLimitationsInfo" />
                    </Alert>
                  )}
                <Box mt={6} />
                {venueHasLunchBenefit &&
                  lunchLimits &&
                  selectedBenefit?.value == "lunch" && (
                    <Alert severity="info">
                      <FormattedMessage
                        id="PaymentSelect.lunchPaymentInfo"
                        values={{
                          min: centsToEuros(lunchLimits.min),
                          max: centsToEuros(lunchLimits.max),
                        }}
                      />
                    </Alert>
                  )}
                <Box mt={6} />
                <AmountInput
                  amount={amount}
                  setAmount={setAmount}
                  validateAmount={(val) => validateAmount(val)}
                  amountError={amountError}
                  expanded={amountExpanded}
                  handleAccordionChange={handleAccordionChange}
                  disabled={amountDisabled}
                />

                <PaymentMethodContainer
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                  expanded={paymentMethodExpanded}
                  handleAccordionChange={handleAccordionChange}
                  setExpanded={setPaymentMethodExpanded}
                  isGiftcardPaymentDisabled={isGiftcardPaymentDisabled}
                  isValidGiftcard={isValidGiftcard}
                  giftcardAccount={giftcardAccount}
                  email={email}
                  setEmail={setEmail}
                  selectedBenefit={selectedBenefit?.value}
                  paymentMethodValidationError={paymentMethodValidationError}
                  setEmailValidationError={setEmailValidationError}
                  renderGiftcardInputContainer={
                    <GiftCardInputContainer
                      loading={giftcardLoading}
                      setLoading={setGiftcardLoading}
                      inputFields={{ claimCode, setClaimCode }}
                      giftcardValidationError={!!giftcardValidationError}
                      setAccount={setGiftcardAccount}
                      claimCodeError={claimCodeError}
                      setClaimCodeError={setClaimCodeError}
                      setMissingClaimCode={setMissingClaimCode}
                    />
                  }
                  renderBenefitSelection={
                    paymentMethod === "benefit" &&
                    !selectedBenefit?.urlParam &&
                    venue?.accepted_benefits?.length > 1 ? (
                      <BenefitSelection
                        benefits={venue.accepted_benefits}
                        selectedBenefit={selectedBenefit?.value}
                        handleBenefitSelection={handleBenefitSelection}
                      />
                    ) : null
                  }
                />
                {isPaymentConfirmVisible && giftcardAccount && (
                  <PaymentConfirmDialog
                    handlePaymentConfirm={handleGiftcardPayment}
                    handleClose={() => setPaymentConfirmVisible(false)}
                    payment={{ amount: centsToEuros(toCents(amount)), email }}
                    venue={venue}
                    giftcardAccount={giftcardAccount}
                  />
                )}
                <Box mt={6} />
                {displayedValidationError && (
                  <Alert severity="error">
                    {intl.formatMessage(
                      {
                        id: displayedValidationError,
                        defaultMessage: intl.formatMessage({
                          id: "GiftcardInput.error.generic",
                        }),
                      },
                      {
                        ...validationErrorValues,
                      }
                    )}
                  </Alert>
                )}
                <Box mt={6} />
                {paymentMethod === "giftcard" && (
                  <>
                    {missingClaimCode && (
                      <Alert severity="error">
                        {intl.formatMessage({ id: missingClaimCode })}
                      </Alert>
                    )}
                    <Box mt={6} />
                    {/* Show email validation only when claimCode has been given */}
                    {showEmailError && (
                      <Alert severity="error">
                        {intl.formatMessage({ id: emailValidationError })}
                      </Alert>
                    )}
                  </>
                )}
                <Box mt={8} />
                <Button
                  onClick={onPaymentClick}
                  className={classes.continueButton}
                >
                  {intl.formatMessage({ id: "PaymentSelect.continue" })}
                </Button>
                <Box mt={8} />
              </Box>
            )}
          </>
        ) : null}
      </>
    </Box>
  );
};

export default PaymentSelect;
