import React from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import messages from "../translations/messages";
import { theme } from "./StyledComponents";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import MuiTheme from "../muiTheme";

const { intlTextsEN, intlTextsFI } = messages;
const i18nConfig = {
  locale: "fi",
  messages: intlTextsFI,
};


export const AppContext = React.createContext({
  locale: "fi",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeLanguage: (lang: string) => {},
  theme,
});

const AppProvider: React.FC = (props) => {
  const [locale, setLocale] = React.useState("fi");

  const onChangeLanguage = (lang: string) => {
    switch (lang) {
      case "fi":
        i18nConfig.messages = intlTextsFI;
        break;
      case "en":
        i18nConfig.messages = intlTextsEN;
        break;
      default:
        i18nConfig.messages = intlTextsFI;
        break;
    }
    setLocale(lang);
    i18nConfig.locale = lang;
  };

  return (
    <AppContext.Provider value={{ locale, onChangeLanguage, theme }}>
      <IntlProvider
        locale={i18nConfig.locale}
        messages={i18nConfig.messages}
        textComponent={React.Fragment}
      >
        <MuiThemeProvider theme={MuiTheme}>
          <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </MuiThemeProvider>
      </IntlProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
